<template>
  <div class="">
    <div class="flex justify-between items-center mb-6">
      <div>
        <h1 class="text-4xl">Test Print</h1>
        <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
      </div>

      <router-link
        to="/branches/create"
        class="
          border
          px-6
          py-2
          rounded-lg
          text-sm
          flex
          justify-center
          items-center
          hover:bg-gray-100
        "
      >
        Add New Branch
        <i class="bx bxs-plus-circle pl-1"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
// import { ipcRenderer } from "electron";
// console.log(ipcRenderer);
export default {
  middleware: "auth",

  methods: {
    printPaper() {
      console.log("printPaper! start");
      // ipcRenderer.send("print", { a: 1, b: 2 });
      console.log("printPaper! end");
    },
  },
};
</script>
